import { makeStyles, Typography } from '@material-ui/core';
import { Cached, Face, Payment } from '@material-ui/icons';
import classNames from 'classnames';
import firebase from 'firebase/app';
import * as React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { currentAuth } from '../recoils';
import flex from '../styles/flex.scss';
import { Blank } from './Blank';

const getCn = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flexWrap: 'wrap'
  },
  left: {
    backgroundColor: '#ffffff'
  },
  google: {
    backgroundColor: '#4a90e2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 2,
    boxShadow: theme.shadows[2],
    color: '#ffffff',
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#77ace9'
    },
    '&>img': {
      margin: -2
    },
    '&>span': {
      whiteSpace: 'nowrap',
      marginLeft: 14,
      marginRight: 20
    }
  },
  disabled: {
    cursor: 'progress',
    backgroundColor: '#e3e3e3',
    '&:hover': {
      backgroundColor: '#e3e3e3' // 打ち消す
    },
    opacity: 0.8,
    boxShadow: 'none'
  },
  banner: {
    margin: 16
  },
  logo: {
    margin: 16
  },
  right: {
    flexGrow: 3,
    backgroundColor: '#edf0f3',
    minWidth: 600,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: 600 // 左右のマージンを揃えるために幅を決め打つ
    }
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    '& > svg': {
      marginRight: 8
    }
  }
}));

/**
 * ログインしていない場合に見せるページ
 */
export function Landing({}) {
  const cn = getCn();

  const userInfoLoadable = useRecoilValueLoadable(currentAuth);

  // 管理者をメールで招待したとき、招待時のメールアドレスでログインさせる
  const params = new URLSearchParams(window.location.search);
  const login_hint = params.get('login_hint');

  const signIn = React.useCallback(() => {
    const provider = new firebase.auth.GoogleAuthProvider();
    if (login_hint) {
      provider.setCustomParameters({ login_hint });
    }

    // アカウントが１つしかなくても必ず選択画面を表示させる
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(error => alert(error.message));
  }, []);

  return (
    <div className={cn.root}>
      <div className={classNames(flex.blank, cn.left, flex.vertical)}>
        <Blank grow={2} />
        <img
          src={require('../resources/banner.png')}
          alt=""
          className={cn.banner}
        />
        <img
          src={require('../resources/hackforplay-admin.png')}
          alt="HackforPlay Admin"
          className={cn.logo}
        />
        <Typography variant="body1" color="textSecondary">
          ハックフォープレイの法人向けサービス
        </Typography>
        <Blank grow={1} />
        <div
          className={classNames(
            cn.google,
            userInfoLoadable.state === 'loading' && cn.disabled,
            userInfoLoadable.state === 'hasValue' &&
              userInfoLoadable.contents &&
              cn.disabled
          )}
          onClick={signIn}
        >
          <img src={require('../resources/google.svg')} alt="" />
          <span>Google でログイン/登録</span>
        </div>
        <Typography color="textSecondary" align="center">
          <br />
          初めての方・ご利用に関するお問い合わせを
          <br />
          ご希望の方は、最初にご登録をお願いいたします
        </Typography>
        <Blank grow={3} />
      </div>
      <div className={cn.right}>
        <Blank grow={3} />
        <img src={require('../resources/admin-description.svg')} alt="" />
        <Blank grow={1} />
        <div>
          <Typography
            variant="subtitle2"
            className={cn.description}
            color="textSecondary"
          >
            <Face />
            メンバーを作成（ID, パスワード発行）
          </Typography>
          <Typography
            variant="subtitle2"
            className={cn.description}
            color="textSecondary"
          >
            <Payment />
            複数人の料金をまとめてお支払い
          </Typography>
          <Typography
            variant="subtitle2"
            className={cn.description}
            color="textSecondary"
          >
            <Cached />
            有料プランの特典を受けられるメンバーを選択可能
          </Typography>
        </div>
        <Blank grow={3} />
      </div>
    </div>
  );
}
