import { AlertProps } from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import Stripe from 'stripe';
import '../config/initializeApp';
import { endpoint } from '../env';
import { requestWithAuth } from '../store/requestWithAuth';
import { atomRewindFamily } from './atomRewind';

export const getUserById = selectorFamily({
  key: 'getUserById',
  get: (id: string) => async () => {
    const snapshot = await firebase
      .firestore()
      .collection('users')
      .doc(id)
      .withConverter(convertToUser)
      .get();
    return snapshot.data();
  }
});

export const popupAlert = atom<AlertProps | undefined>({
  key: 'popupAlert',
  default: undefined
});

/**
 * Firebase Authentication の現在の認証情報
 * undefined であれば状態は未確定である
 * null であればログインしていない（ログアウトされた）
 */
export const currentAuth = atom<firebase.UserInfo | null | undefined>({
  key: 'currentAuth',
  // OAuth プロバイダからリダイレクトされた状態なら認証を行う
  // ブラウザ ウィンドウを閉じたり React Native でアクティビティが破棄されたりした場合でも、状態が維持されることを示します。この状態をクリアするには、明示的なログアウトが必要です
  default: firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => firebase.auth().currentUser),
  dangerouslyAllowMutability: true // UserInfo は deep freeze できない
});

/**
 * チーム識別子の配列
 */
export const teamIdsAtom = atom<string[]>({
  key: 'teamIdsAtom',
  default: []
});

/**
 * チームの基本情報
 */
export const teamsAtom = atomFamily({
  key: 'teamsAtom',
  default: async (id: string) => {
    const snapshot = await firebase
      .firestore()
      .collection('teams')
      .doc(id)
      .withConverter(convertToTeam)
      .get();
    return snapshot.data();
  }
});

/**
 * チームに紐づいている Stripe の顧客情報
 */
export const teamCustomers = atomFamily({
  key: 'teamCustomerAtoms',
  default: (teamId: string) => {
    return requestWithAuth<Stripe.Customer>(
      endpoint + `/teamCustomers?teamId=${teamId}`
    );
  }
});

const defaultFilters: { [key in FilterType['type']]: FilterType } = {
  rights: {
    type: 'rights',
    showDisabled: true,
    showEnabled: true
  },
  tags: {
    type: 'tags',
    showEmpty: false,
    showTags: []
  }
};

export const defaultPreference: IPreference = {
  filters: [defaultFilters.rights, defaultFilters.tags]
};

/**
 * あるチームに所属しているチームメンバーの ID を全て列挙する
 */
export const teamMemberIdsState = atomFamily({
  key: 'teamMemberIdsState',
  default: (teamId: string) => [] as string[]
});

/**
 * 支払いが有効になっているチームメンバーの人数を取得する
 */
export const activeMembersNum = selectorFamily({
  key: 'activeMembersNum',
  get:
    (teamId: string) =>
    ({ get }) => {
      const ids = get(teamMemberIdsState(teamId));
      return ids.filter(uid => {
        const member = get(teamMembersAtom({ teamId, uid }));
        return member?.rights;
      }).length;
    }
});

/**
 * チームメンバーの一人一人の詳細情報を保持する
 */
export const teamMembersAtom = atomRewindFamily({
  key: 'teamMembersAtom',
  dangerouslyAllowMutability: true, // IMemberにReference Typeを使っているので、deep freezeできない
  default: (params: { teamId: string; uid: string }) => async () => {
    const ds = await firebase
      .firestore()
      .collection('teams')
      .doc(params.teamId)
      .collection('members')
      .doc(params.uid)
      .withConverter(convertToMember)
      .get();
    return ds.data();
  }
});

/**
 * チームに紐づいている設定情報
 */
export const teamPreference = selectorFamily({
  key: 'teamPreference',
  get:
    (teamId: string) =>
    async ({ get }) => {
      const uid = get(currentAuth)?.uid;
      const written = get(teamPreferenceWrite(teamId));
      if (!(written instanceof DefaultValue)) {
        return written;
      }
      if (!uid) {
        throw new NeedSignIn();
      }
      const snapshot = await firebase
        .firestore()
        .collection('teams')
        .doc(teamId)
        .collection('preferences')
        .doc(uid)
        .withConverter(convertToPreference)
        .get();
      return snapshot.data();
    }
});

/**
 * チームに紐づいている設定情報を更新するための Atom
 * この Atom は write-only. read したい場合は teamPreference を使う
 */
export const teamPreferenceWrite = atomFamily<
  IPreference | DefaultValue,
  string
>({
  key: 'teamPreference',
  default: new DefaultValue()
});

export const memberWorksState = selectorFamily({
  key: 'memberWorksState',
  get: (params: { teamId: string; uid: string }) => async () => {
    const { uid, teamId } = params;
    const url = `${endpoint}/memberWorks?teamId=${teamId}&memberId=${uid}`;
    const result = await requestWithAuth(url);
    return result.works as IWork[];
  }
});

/**
 * 管理者の詳細情報。このドキュメントが存在することは管理者であることを保証しない
 */
export const adminDetailsAtom = atomFamily({
  key: 'adminDetailsAtom',
  default: async (teamId: string) => {
    const adminDetails: { [uid: string]: IAdminDetail | undefined } = {};
    const qs = await firebase
      .firestore()
      .collection('teams')
      .doc(teamId)
      .collection('adminDetails')
      .withConverter(convertToAdminDetail)
      .get();
    qs.forEach(ds => {
      adminDetails[ds.id] = ds.data();
    });
    return adminDetails;
  }
});

/**
 * 管理者がユーザーのパスワードを変更した履歴
 */
export const passwordHistoriesState = atomRewindFamily({
  key: 'passwordHistoriesState',
  default: (params: { teamId: string; uid: string }) => async () => {
    // あるメンバーに対するパスワードの変更履歴を取得する
    const qs = await firebase
      .firestore()
      .collection('teams')
      .doc(params.teamId)
      .collection('passwordHistories')
      .where('uid', '==', params.uid)
      .orderBy('updatedAt', 'desc')
      .withConverter(convertToPasswordHistory)
      .get();
    return qs.docs.map(ds => ds.data());
  }
});

/**
 * メンバーの識別に使われているタグの一覧。実際のデータから動的に作成する
 */
export const completeTagsAtom = atomFamily({
  key: 'completeTagsAtom',
  default: (teamId: string) => [] as string[]
});

export const convertToAdminDetail: firebase.firestore.FirestoreDataConverter<IAdminDetail> =
  {
    fromFirestore: snapshot => snapshot.data() as IAdminDetail,
    toFirestore: (data: IAdminDetail) => data
  };

export const convertToMember: firebase.firestore.FirestoreDataConverter<IMember> =
  {
    fromFirestore: snapshot => snapshot.data() as IMember,
    toFirestore: (data: IMember) => data
  };

export const convertToPasswordHistory: firebase.firestore.FirestoreDataConverter<IPasswordHistory> =
  {
    fromFirestore: snapshot => snapshot.data() as IPasswordHistory,
    toFirestore: (data: IPasswordHistory) => data
  };

export const convertToPreference: firebase.firestore.FirestoreDataConverter<IPreference> =
  {
    fromFirestore: snapshot => snapshot.data() as IPreference,
    toFirestore: (data: IPreference) => data
  };

export const convertToTeam: firebase.firestore.FirestoreDataConverter<ITeam> = {
  fromFirestore: snapshot => snapshot.data() as ITeam,
  toFirestore: (data: ITeam) => data
};

export const convertToUser: firebase.firestore.FirestoreDataConverter<IUser> = {
  fromFirestore: snapshot => snapshot.data() as IUser,
  toFirestore: (data: IUser) => data
};

export function getQuantity(customer?: Stripe.Customer) {
  const subscription = customer?.subscriptions?.data?.find(
    sub => sub.status !== 'canceled'
  );
  const item = subscription?.items?.data?.find(it => it.price?.active);
  return item?.quantity;
}

export class NeedSignIn extends Error {
  constructor() {
    super('Need sign in');
  }
}
